* {
  padding: 0;
  margin: 0;
}

:root {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

body {
  font-family: 'Montserrat', 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
html,
body {
  height: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-input[disabled] {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.cke_toolbox span:nth-child(4),
.cke_toolbox span:nth-child(5) {
  display: none;
}

button {
  border: 0;
  font-size: 100%;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.MuiBox-root-129 {
  padding: 0 !important;
}

.w-100 {
  width: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-3 {
  margin-top: 12px;
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-6 {
  margin-bottom: 24px;
}

.pb-6 {
  padding-bottom: 24px;
}

.btn-height {
  height: 36px !important;
}

.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  border-bottom: 1px solid #d5d5d5 !important;
  border-right: 1px solid #d5d5d5 !important;
}

.ant-table-cell-fix-right-first::after {
  border-bottom: 1px solid #d5d5d5 !important;
  border-right: 1px solid #d5d5d5 !important;
}

.ant-table-thead > tr > th.ant-table-cell {
  border-right: 1px solid #d5d5d5 !important;
}

.btn-primary {
  color: #000000 !important;
}

.btn-danger {
}

.border-none .ant-table-tbody > tr > td {
  border: none;
}

.center-element {
  display: flex !important;
  justify-content: center !important;
}

.btn-secondary {
  color: #ffffff !important;
  background-color: #296c9f !important;
  border-color: #296c9f !important;
}

.ant-layout-sider-trigger {
  background-color: #ffffff !important;
  border-right: 1px solid #15ba92 !important;
  color: #29a366 !important;
}

.ant-layout-sider {
  background-color: #ffffff !important;
  border-right: 1px solid #15ba92 !important;
}

.ant-layout-sider-children {
  background-color: #ffffff !important;
}

.ant-menu-submenu-title {
  background-color: #ffffff;
  color: #000000;
}

.ant-layout-sider-children > .ant-menu {
  background-color: #ffffff;
}

.ant-menu-item {
  color: #000000 !important;
}

.ant-layout-sider-children > .ant-menu-sub {
  background-color: #ffffff !important;
}

.ant-menu-sub {
  background-color: #ffffff !important;
}

.ant-menu-submenu-title:hover {
  background-color: #26a68d !important;
}

.ant-layout-header {
  background-color: #f3fcfa !important;
}

.ant-layout-header > div > div > ul.ant-menu {
  background-color: #f3fcfa !important;
}

.logo {
  position: relative;
  top: 12px;
  left: -30px;
}

.ant-table-cell {
  overflow-wrap: anywhere !important;
}

.custom-table .ant-table-thead > tr > th {
  background: #f3fcfa !important;
  border-top: 1px solid #78be9b !important;
  border-bottom: 1px solid #78be9b !important;
  color: #1f7a4c;
}

.ant-table-thead > tr > th.ant-table-cell {
  border-right: none !important;
}

.custom-table .ant-table-thead > tr > th:last-of-type {
  border-right: 1px solid #78be9b !important;
}

.custom-table .ant-table-thead > tr > th:first-of-type {
  border-left: 1px solid #78be9b !important;
}

.custom-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #78be9b !important;
}

.custom-table .ant-table-tbody > tr > td:first-of-type {
  border-left: 1px solid #78be9b !important;
}

.custom-table-striped .ant-table-tbody > tr:nth-of-type(odd) {
  background-color: #f3fcfa !important;
}

.custom-table-striped
  .ant-table-tbody
  > tr:nth-of-type(odd)
  > td.ant-table-cell-fix-right {
  background-color: #f3fcfa !important;
}

.custom-table .ant-table-tbody > tr > td:last-of-type {
  border-right: 1px solid #78be9b !important;
}

.ant-menu-item-selected {
  color: #ffffff !important;
}

.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: #000000;
}

.custom-menu > li:hover::after {
  border-bottom: none !important;
}

.custom-menu > li::after {
  border-bottom: none !important;
}

.ant-upload-list-item .ant-upload-list-item-name {
  flex: none !important;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item:hover {
  background: none !important;
}
.ant-menu-horizontal {
  border-bottom: none !important;
}
.avatar_menu > div:hover {
  background: none !important;
}
.avatar_menu > div {
  background: none !important;
}
.avatar_header {
  float: right;
}
